import { useRef } from 'react';
import { Box, Table, Pagination, TextInput } from '@mantine/core';
import { useStyles } from './style';
import { useEffect, useState } from 'react';
import filter from "../../../assets/icons/filterIcon.svg"
import { SearchIcon } from '../../../assets/icons/search';
import { data } from "./properties";
import { baseURL } from '../../../config/urls';
import { getHeader, doRedirectIfUnAuth} from '../../../config/userSession';
import SendQueryModal from './sendquerymodel';
import { noRecordFound } from '../../../utils/const';

function TenantLandlordList() {

    const [modelOpen, setModelOpen] = useState(false);
    const { classes, theme } = useStyles(useStyles)
    const [activePage, setActivePage] = useState(1);
    const [selectedLandlordEmail, setSelectedLandlordEmail] = useState("");
    const [entries , setEntries] = useState([]);
    const [pageLoadEntries , setPageLoadEntries] = useState([]);
    const [totalPage , setTotalPage] = useState(1);
    const [pageSize , setPageSize] = useState(10);
    const cacheListing = useRef({});
    const params = new URLSearchParams();
    params.append('page' , activePage)
    params.append('pSize' , pageSize)

    const pageChangeHandler = (page) => {
        setActivePage(page)
    }

    const fetchContactForm =async () => {
        if (params in cacheListing) {
            setEntries(cacheListing[params]);
            return;
        }
        const response = await fetch(`${baseURL}/account/tenant/landlords`, {
            method: 'GET',  
            headers: getHeader(),
            
        })
        console.log(response)
        if (response.ok) {
            let contactFormListing = await response.json();
            console.log('form listings are ', contactFormListing)
            setEntries(contactFormListing.data);
            setPageLoadEntries(contactFormListing.data);
           
            setTotalPage((contactFormListing.totalCount + pageSize - 1)/ pageSize);
            cacheListing[params]= contactFormListing.data; 
        } else {
            doRedirectIfUnAuth(response.status)

        }
    }

    useEffect(() => {
        fetchContactForm()
    }, [activePage])
    
    const handleQuerySent = () => {
        setModelOpen(false);
    };
  
    const rows =  entries.map((element, index) => (
        <tr className={index % 2 === 0 ? classes.tableRowWhite : classes.tableRow} key={`${element.firstName}${index}`}>
            <td className={classes.tableData}>{element.firstName  +  ' ' + element.lastName }</td>
            <td className={classes.tableData}>{element.email}</td>
            <td className={classes.tableData}>{element.phoneNumber}</td>
            <td className={classes.tableData}><p onClick={() => { setSelectedLandlordEmail(element.email); setModelOpen(true)}}   className={classes.status}>SEND QUERY</p></td>
        </tr>
    ));

    const handleSearch = (e) => {
        const value = e.target.value;
        if(value === ""){
            setEntries(pageLoadEntries)
        }else{
        setEntries( prev => prev.filter(item => `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()}`.includes(value.toLowerCase())) )
    }}

    console.log("entries", entries)

    return (
        <Box className={classes.wrapper}>
            <Box className={classes.headerAndSearch}>
            <p className={classes.title} >{data.titles.landlordList}</p>
            <Box className={classes.searchAndFilter}>
                <TextInput icon={<SearchIcon/>} onChange={handleSearch} radius={"xl"} className={classes.searchInput} placeholder={data.titles.search} />    
                <Box className={classes.filterContainer}>
                <img className={classes.filterIcon} src={filter} alt='search-input' />
                <p className={classes.filter}>{data.titles.filter}</p>
                </Box>    
            </Box>
            </Box>
            <Box className={classes.tableContainer}>
            <Table className={classes.table}>
                <thead className={classes.tableHead}>
                    <tr >
                        {data.tableHeading.map(heading => {
                            return <th className={classes.columnHeading}>{heading}</th>
                        })}
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
            </Box>
            {
                rows.length === 0 ? <p className='no-record-found'> {noRecordFound} </p>
            :
            <Pagination size={"md"} defaultValue={activePage} onChange={pageChangeHandler} className={classes.pagination} siblings={1} total={totalPage} />
            }
            <SendQueryModal modalOpen={modelOpen} onClose={()=> setModelOpen(false)} landlordEmail={selectedLandlordEmail} onQuerySent={handleQuerySent}/>

        </Box>
    );
}

export default TenantLandlordList;