import { createStyles } from "@mantine/styles";

export const useStyles = createStyles(theme => ({
 
    notificationsContainer : {
        "section" : {
            position : "fixed",
            right : 0,
            borderTopLeftRadius : 20,
            borderBottomLeftRadius : 20,
            width : 500,
            [theme.fn.smallerThan(550)] : {
                width : "100%",
            minHeight : "100%",
            borderTopLeftRadius : 0,
            borderBottomLeftRadius : 0,
            // top : 0,
            bottom : 0
            }
        }
    },
    opened : {
        right : 0
    },
    header : {
        width : '95%',
        display : "flex",
        justifyContent : "space-between",
        alignItems : "center",
        margin : "auto"
    },
    title : {
        color : theme.colors.secondary[0],
        fontFamily : `Medium , ${theme.fontFamily}`,
        fontSize : 21
    },
    closeIcon : {
        width : 12,
        height : 12,
        ":hover" : {
            cursor : "pointer"
        }
    },
    notifications : {
        display : "flex",
        flexDirection : "column",
        marginTop : 20
    },
    notification : {
        display : "flex",
        justifyContent : "space-between",
        padding : 15,
        alignItems : "center",
        background : theme.colors.tertiary[0],
        borderBottom : `1px solid #D9E2E4`,  
              ":first-child" : {
            borderTopLeftRadius : 20,
            borderTopRightRadius : 20
        },
        ":last-child" : {
            borderBottomLeftRadius : 20,
            borderBottomRightRadius : 20
        }
    },
    read : {
        background : "#F7F9FA",
        opacity : 0.5
    },
    titleAndDate : {
        display : "flex",
        flexDirection : "column",
        rowGap : 5
    },
    notificationTitle : {
        fontFamily : `Regular, ${theme.fontFamily}`,
        fontSize : 13,
    },
    notificationDate : {
        fontFamily : `Light, ${theme.fontFamily}`,
        fontSize : 9
    },
    deleteIcon : {
        fontSize : 25
    }
}))