export const data = {
    elements : [
        {
            name: "Landlord Name 1",
            status: "ACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 2",
            status: "INACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 3",
            status: "DISABLED",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 4",
            status: "INACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 5",
            status: "ACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 6",
            status: "DISABLED",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 7",
            status: "ACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 8",
            status: "DISABLED",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 9",
            status: "ACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 10",
            status: "INACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 11",
            status: "DISABLED",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 12",
            status: "ACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 13",
            status: "ACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 14",
            status: "INACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 15",
            status: "DISABLED",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 16",
            status: "INACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 17",
            status: "ACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 18",
            status: "DISABLED",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 19",
            status: "ACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 20",
            status: "DISABLED",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 21",
            status: "ACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 22",
            status: "INACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 23",
            status: "DISABLED",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        {
            name: "Landlord Name 24",
            status: "ACTIVE",
            startDate : 'DD/MM/YYYY',
            endDate : 'DD/MM/YYYY'
        },
        
        

    ],
    titles : {
        landlordList : "Landlord List",
        search : "Search",
        filter : "Filter",
        message : "Message",
        title:  "Title",
        sendquery : "Send Query To Landlord",
        cancel : "Cancel",
        send : "Send",

    },
    tableHeading : [
        'Landlord Name',
        'Email',
        'Phone',
        'Send Query ',
    ]
}

export const statusClass = (status, classes) => {
    if (status === 'active') {
        return classes.active
    }
    if (status === 'disabled') {
        return classes.disabled
    }
    if (status === 'inactive') {
        return classes.inactive
    }
}