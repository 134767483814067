import { Box,Modal, TextInput, Textarea } from '@mantine/core'
import React, { useState } from 'react'
import { useStyles } from './style'
import { data } from '../properties'
import { useForm } from '@mantine/form';
import { RxCross2 } from 'react-icons/rx';
import Button from "../../../../utils/Button"
import {placeholderError,queryMsg} from "./properties"
import { baseURL } from '../../../../config/urls'; 
import { getHeader,doRedirectIfUnAuth } from '../../../../config/userSession'; 
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
const SendQueryModal = ({ modalOpen, onClose, handleApply,onQuerySent,landlordEmail }) => {
  const { classes, theme } = useStyles(useStyles);
  const { titles } = data;
  const handleCancel = () => {
    onClose()
  }

  const form = useForm({
    initialValues: {
      title: '',
      message: '',
    },
    validate: {
      title: (value) =>
        value === ''
          ? placeholderError.title[0]
          : (value.length < 3 || value.length > 50)
          ?  placeholderError.title[1]
            : null,
      message: (value) => 
        value === ''
        ? placeholderError.message[0] : 
        (value.length > 2500 || value.length < 3 )? 
      placeholderError.message[1] : 
        null,
    }
  })

  const showNotification = (notificationType, message, title) => {
    let colorCode = notificationType == 'success' ? 'secondary.0' : 'red';
    notifications.show({
      withCloseButton: true,
      autoClose: 2000,
      title: title,
      message: message,
      color: colorCode,
      icon: notificationType == 'success' ? <IconCheck size="1.1rem" /> : <IconX />,
      style: { backgroundColor: theme.colors.background[0] },
    });
  }


  const updateFormHandler = async () => {
    try {
      const response = await fetch(`${baseURL}/account/send-query`, {
        method: 'POST',
        headers: getHeader(),
        body: JSON.stringify({
          landlordEmail: landlordEmail,
          title: form.values.title,
          message: form.values.message,
        }),
      });

      if (response.ok) {
        showNotification('success',queryMsg.successTitle);
        onQuerySent();
      } else {
        if (response.status === 401) {
          doRedirectIfUnAuth(response.status);
        }
        showNotification(queryMsg.error);
      }
    } catch (error) {
      showNotification(queryMsg.unknownError,error);
    }
  };
 

  return (
    <Modal className={classes.sendQueryModal} radius={20} onClose={handleCancel}  closeOnEscape={true} overlayProps={{
      opacity: 0.1}} closeButtonProps={{display: "none"}} size={"sm"} opened={modalOpen} centered >
      <form onSubmit={form.onSubmit(() => {
          updateFormHandler()
        })}>
         <Box className={classes.sendQueryBox}>
        <Box className={classes.header}>
        <p className={classes.title}>{titles.sendquery}</p>
        <RxCross2 className={classes.closeIcon} onClick={handleCancel} />
        </Box>

        <Box className={classes.inputFields}>
               <Box className={classes.inputWrapper}>
                  <label className={classes.label}>{titles.title}</label>
                  <TextInput
                    className={classes.textInput}
                    variant="unstyled"
                    placeholder="Query Title"
                    {...form.getInputProps('title')}
                  />
                </Box>
                <Box className={`${classes.inputWrapper} ${classes.addressInput}`}>
                  <label className={classes.label}>{titles.message}</label>
                  <Textarea placeholder='Message here' {...form.getInputProps("message")} className={classes.textarea} />
                </Box>
        </Box>
        <Box className={classes.actions}>
          <p onClick={handleCancel} className={classes.cancel}>{titles.cancel}</p>
          {/* <Button type={'submit'} className={classes.apply}>{titles.send}</Button> */}
          <Button type={'submit'}  bgColor={
                theme.colors.primary[0] 
            } title='Send' />
        </Box>
      </Box>
      </form>
     
    </Modal>
    )
}

export default SendQueryModal