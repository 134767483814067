import { createStyles, rem } from '@mantine/core';
import bgMobile from '../../assets/images/bgMobile.png';

export const useStyles = createStyles((theme) => ({
  wrapperContainer: {
    background: `linear-gradient(121.51deg, #E1F4F5 30.38%, #E1F4F5 53.04%, #D6EBF0 71.29%)`,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: "80%",
    margin: "auto",
    height: 110,
    alignItems: 'center',
    position: "relative",
    [theme.fn.smallerThan(1400)]: {
      // paddingLeft : 100,
      // paddingRight : 100
      width: "90%",
    },
    [theme.fn.smallerThan(1050)]: {
      width: "100%",
      paddingLeft: 20,
      paddingRight: 10
    },
  },
  linksAndUser: {
    display: "flex",
    columnGap: 25,
    alignItems: "center",
    [theme.fn.smallerThan(1110)]: {
      paddingLeft: 10,
      paddingRight: 10,
      columnGap: 10
    },
    [theme.fn.smallerThan(1050)]: {
      display: "none"
    },
  },
  links: {
    display: "flex",
    columnGap: 30,
    paddingTop: 10,
    [theme.fn.smallerThan(1200)]: {
      columnGap: 20
    }
  },
  link: {
    fontFamily: `Medium, ${theme.fontFamily}`,
    fontSize: 13,
    textDecoration: "none",
    paddingBottom: 7,
    color: theme.colors.text[1],
    transition: '.4s',
    ":hover": {
      color: theme.colors.primary[0]
    }
  },
  activeLink: {
    fontFamily: `Medium, ${theme.fontFamily}`,
    fontSize: 13,
    textDecoration: "none",
    paddingBottom: 5,
    color: theme.colors.text[1],
    borderBottom: `1px solid ${theme.colors.primary[0]}`,
    color: theme.colors.primary[0]
  },
  logo: {
    width: 150,
    marginTop: 10,
    [theme.fn.smallerThan(1200)]: {
      width: 130,
      marginTop: 10
    }
  },
  demoUser: {
    width: 30,
    height: 30,
  },
  demoProfile: {
    display: 'inline-block',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#1ED095',
    textAlign: 'center',
    lineHeight: '30px', // Center the text vertically
    color: '#fff', // Text color
    fontSize: '16px', // Adjust font size as needed
  },
  menuTarget: {
    display: "flex",
    columnGap: 5,
    alignItems: "center",
    background: "white",
    justifyContent: "center",
    height: 40,
    borderRadius: 50,
    padding: 10,
    marginTop: 10,
    ":hover": {
      cursor: 'pointer'
    }
  },
  downArrow: {
    fontSize: 25,
    color: theme.colors.text[1]
  },

  dropdown: {
    padding: 10,
    borderRadius: 28,
    boxShadow: '0 0 50px lightgray',
  },
  dropdownContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    // justifyContent : "center",
    alignItems: 'flex-start',
    marginTop: 100,
    background: 'white',
    borderRadius: 28,
    height: 50,
    transition: '.5s',
    overflowY: 'hidden',
  },
  dropdownContainerOpened: {
    height: 150,
    overflowY: 'visible'
  },
  menuItems: {
    width: '100%',
    paddingLeft: 50
  },
  menuItem: {
    fontFamily: `Regular, ${theme.fontFamily}`,
    width: '100%',
    fontSize: 16,
    transition: '.4s',
    color: theme.colors.text[1],
    paddingTop: 10,
    paddingBottom: 10,
    ":first-child": {
      color: theme.colors.primary[0],
      fontWeight: 600,
      paddingTop: 12,
      borderTop: `1px solid ${theme.colors.outline[2]}`
    },
    ":hover": {
      backgroundColor: "transparent",
      color: theme.colors.primary[0],
    }
  },
  notificationsIcon: {
    padding: 7,
    background: "white",
    borderRadius: "50%",
    width: 40,
    height: 40,
    marginTop: 10,
    ":hover": {
      cursor: "pointer"
    }
  },
  //mobile
  hamburgerContainer: {
    backgroundColor: theme.colors.secondary[0],
    borderRadius: '50%',
    width: 45,
    height: 45,
    padding: 12,
    display: 'flex',
    alignItems: "center",

  },
  hamburger: {
    width: '100%',
    height: '100%',
  },
  mobileNotification: {
    width: 30,
    height: 30,
    marginTop: 7,
    ":hover": {
      cursor: "pointer"
    }
  },
  mobileNotificationAndHamburger: {
    display: "none",
    columnGap: 30,
    [theme.fn.smallerThan(1050)]: {
      display: "flex"
    }
  },
  //opened mobile nav 
  openedMobileNav: {
    backgroundColor: theme.colors.secondary[0],
    width: "100%",
    height: 0,
    position: "fixed",
    left: 0,
    top: 0,
    userSelect: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 99,
    backgroundSize: 'auto',
    backgroundPosition: "bottom",
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${bgMobile}), linear-gradient(121.51deg, ${theme.colors.secondary[0]} 30.38%, ${theme.colors.secondary[0]} 53.04%, ${theme.colors.secondary[0]} 71.29%)`,
    transition: ".5s",
    overflowY: "scroll",


  },
  openTheNav: {
    height: '100vh',
    width: "100vw",
    paddingBottom: 100
  },

  mobileLinks: {
    display: "flex",
    flexDirection: "column",
    rowGap: 30,
    padding: 20,
    width: "95%",
    marginTop: 15,
    [theme.fn.smallerThan(650)]: {
      marginTop: 0
    },
  },
  mobileLink: {
    color: "white",
    textDecoration: "none",
    width: "fit-content",
    paddingBottom: 5,
    transition: '.5s',
    fontFamily: `Regular , ${theme.fontFamily}`,
  },
  activeMobileLink: {
    textDecoration: "none",
    width: "fit-content",
    paddingBottom: 5,
    transition: '.5s',
    fontFamily: `Regular , ${theme.fontFamily}`,
    color: theme.colors.primary[0],
    borderBottom: `2px solid ${theme.colors.primary[0]}`,

  },

  imageAndUsername: {
    display: "flex",
    columnGap: 10,
    alignItems: "center"
  },
  userName: {
    fontFamily: `Medium , ${theme.fontFamily}`,
    fontSize: 15,
    color: theme.colors.text[1]
  },
  menuTargetMobile: {
    width: "100%",
    display: "flex",
    columnGap: 5,
    alignItems: "center",
    justifyContent: "space-between",
    height: 50,
    borderRadius: 0,
    padding: 10,
    WebkitTapHighlightColor: "transparent",
    transition: '.5s',
    ":hover": {
      cursor: 'pointer'
    }
  },
  dropdownClosed: {
    // borderBottomLeftRadius : 0,
    // borderBottomRightRadius : 0,
  },
  crossIcon: {
    padding: 12,
    width: 40,
    height: 40,
    background: "white",
    borderRadius: 50,
    position: "absolute",
    top: 20,
    right: 25,
    ':hover': {
      cursor: "pointer"
    }
  },
  crossIconModal: {
    marginTop: '1vw',
    zIndex: 1000,
    padding: 12,
    width: 40,
    height: 40,
    background: "white",
    borderRadius: 50,
    position: "absolute",
    top: 20,
    right: 25,
    ':hover': {
      cursor: "pointer"
    },
    [theme.fn.smallerThan(500)]: {
      marginTop: '4vw',
    }
  },
  btn: {
    background: theme.colors.primary[0],
    width: "60%",
    marginTop: 30,
    fontFamily: `Regular , ${theme.fontFamily}`,
    [theme.fn.smallerThan(500)]: {
      width: "85%"
    }
  },
  invitebutton: {
    backgroundColor: `${theme.colors.primary[0]}`,
    fontFamily: `Light , ${theme.fontFamily}`,
    fontFamily: `SemiBold , ${theme.fontFamily}`,
    fontSize: 16,
    letterSpacing: 0.5,
    width: 120,
    ":hover": {
      backgroundColor: theme.colors.primary[0],
    },
    height: 43,
    [theme.fn.smallerThan('xs')]: {
      width: rem(270),
      textAlign: 'center',
    },
    [theme.fn.smallerThan(500)]: {
      minWidth: "100%"
    }
  },
  inviteButtonSuperAdmin: {
    backgroundColor: `${theme.colors.primary[0]}`,
    fontFamily: `Light , ${theme.fontFamily}`,
    fontFamily: `SemiBold , ${theme.fontFamily}`,
    fontSize: 16,
    letterSpacing: 0.5,
    width: 168,
    ":hover": {
      backgroundColor: theme.colors.primary[0],
    },
    height: 49,
    [theme.fn.smallerThan('xs')]: {
      width: rem(327),
      textAlign: 'center',
    },
    [theme.fn.smallerThan(500)]: {
      minWidth: "100%"
    }
  },
  label: {
    color: `${theme.colors.text[1]}`,
    opacity: 0.5,
    paddingBottom: "10px",
    fontSize: '16px',
    fontFamily: 'Regular'
  },
  title: {
    fontFamily: 'Medium',
    color: `${theme.colors.secondary[0]}`,
    fontSize: "26px",
  },
  modalText: {
    color: `${theme.colors.text[1]}`,
    opacity: 0.5,
    paddingBottom: '10px',
    fontSize: '16px',
    fontFamily: 'Regular',
    marginTop: 20
  },
  textInput: {

    color: 'white',
    paddingBottom: '0px',
    paddingLeft: '0px',
    marginBottom: '0px',
    'input': {
      fontSize: '16px !important',
      fontFamily: 'Medium !important',
      background: `${theme.colors.background[2]} !important`,
      border: '0px !important',
      borderRadius: '16px !important',
      minHeight: 56
    },
    'textarea': {
      fontSize: '16px !important',
      fontFamily: 'Medium !important',
      background: `${theme.colors.background[2]} !important`,
      minHeight: '14vw',
      border: '0px !important',
      borderRadius: '16px !important',
      [theme.fn.smallerThan('xs')]: {
        minHeight: '178px',
      },
    },
    '.mantine-InputWrapper-error': {
      color: '#ED2424',
      fontFamily: 'Regular',
      fontSize: '16px'
    }

  },
  cancelButton: {
    color: `${theme.colors.secondary[0]}`,
    backgroundColor: `transparent !important`,
    fontFamily: `SemiBold , ${theme.fontFamily}`,
    fontSize: 16,
    letterSpacing: 0.5,
    width: 120,
    ":hover": {
      backgroundColor: `transparent !important`,
    },
    height: 43,
    [theme.fn.smallerThan('xs')]: {
      width: rem(270),
      // textAlign:'center',
    },
    [theme.fn.smallerThan(500)]: {
      minWidth: "100%"
    }
  },
  errorWrapper: {
    color: '#ED2424',
    fontFamily: 'Regular',
    fontSize: '16px'
  },
  footerWrapper: {
    display: 'flex',
    justifyContent: 'center',
    [theme.fn.smallerThan('xs')]: {
      flexWrap: 'wrap',
      flexDirection: 'column-reverse'
    },

  }
}));