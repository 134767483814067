import { Box, Menu } from '@mantine/core';
import logoUrl from '../../assets/icons/tripleNet.svg'
import Button from "../../utils/Button/index"
import { useStyles } from './style';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { BiChevronDown, BiChevronUp } from "react-icons/bi"
import user from "../../assets/images/user.png"
import unreadnotification from "../../assets/icons/UnreadNotification.svg"
import readnotifications from "../../assets/icons/notifications.svg"
import hamburger from "../../assets/icons/hamburger.svg"
import cross from "../../assets/icons/cross.svg"
import { useEffect, useRef, useState } from 'react';
import { getLinks, properties } from './properties';
import { Outlet } from "react-router-dom";
import { getUserType, clearUserData, getUserData, getHeader, doRedirectIfUnAuth } from '../../config/userSession';
import InvitePopup from './invitePopup';
import Notifications from './notifications';
import { baseURL } from '../../config/urls';
import { useQuickstartContext } from '../../context';

const Header = ({ }) => {
  const bodyRef = useRef(null);
  const userType = getUserType();
  const { classes, theme } = useStyles(useStyles);
  const [mobileNavOpened, setMobileNavOpened] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false)
  const [notificationsOpened, setNotificationsOpened] = useState(false)
  const [isProfile, setIsProfile] = useState(false)
  const [userProfileImage, setUserProfileImage] = useState('')
  const [userProfileImageKey, setUserProfileImageKey] = useState()
  const navigate = useNavigate();
  const openMobileNavHandler = (boolean) => {
    setMobileNavOpened(boolean)
  }
  const doLogout = () => {
    clearUserData()
    window.location.reload();
  }
  const { state, dispatch, profilePictureUrl } = useQuickstartContext();
console.log({state, profilePictureUrl})

useEffect(() => {
  // This effect will run whenever profilePictureUrl changes
  console.log('Profile picture updated:', profilePictureUrl);
}, [profilePictureUrl]); // Dependency array includes profilePictureUrl

  const { titles, notifications } = properties;

  const inviteButton = () => {
    if (userType === properties.userType.superAdmin) {
      return titles.inviteAdmin
    }
    return titles.invite
  }

  const notificaitionUnread = notifications.filter(notificatoin => notificatoin.status === 'unread').length > 0;
  const userDetails = getUserData()
  console.log("@@@@", userDetails)
  const searchProfile = () => {
    if (userDetails.imageUrlKey !== null && userDetails.imageUrlKey !== undefined && userDetails.imageUrlKey !== "") {
      setIsProfile(true)
    }
    else {
      setIsProfile(false)
    }
  }

  useEffect(() => {
    searchProfile();
  }, [userDetails]);

  useEffect(() => {
    setUserProfileImageKey(userDetails.imageUrlKey)
  },[userDetails.imageUrlKey])

  async function fetchProfileImage() {
    const response = await fetch(`${baseURL}/account/profile-image/${userProfileImageKey}`, {
      method: 'GET',
      headers: getHeader(),
    });
    if (response.ok) {
      const res = await response.json();
      setUserProfileImage(res.imageUrl)
  
    } else {
      // setLoading(false);
      doRedirectIfUnAuth(response.status);
    }
  }

  useEffect(() => {
    if(userProfileImageKey)
      fetchProfileImage();
  }, [userDetails,userProfileImageKey]);

  const handleOpenProfilePage = () => {
    navigate(`/${userType.toLowerCase()}/profile`)
    openMobileNavHandler(false)
  }
  console.log({userProfileImage, isProfile})
  //for mobile navbar when opened
  document.querySelector("body").style.overflowY = mobileNavOpened ? "hidden" : 'scroll'

  console.log({isProfile})
  return (
    <>
      <Box className={classes.wrapperContainer}>
        <Box className={classes.wrapper}>
          <img className={classes.logo} src={logoUrl} alt='logo' />
          <Box className={classes.linksAndUser}>
            <Box className={classes.links}>
              {getLinks(userType).map(element => {
                return <NavLink className={({ isActive, isPending }) => isActive ? classes.activeLink : classes.link} to={element.link} >{element.title}</NavLink>
              })}
            </Box>
            {userType === properties.userType.landlord || userType === properties.userType.tenant ? null : <InvitePopup />}

            <img onClick={() => setNotificationsOpened(true)} className={classes.notificationsIcon} src={notificaitionUnread ? readnotifications : unreadnotification} alt="notifications" />
            <Menu>
              <Menu.Target>
                <Box className={classes.menuTarget}>
                {!isProfile ? <span className={classes.demoProfile}> 
                  {userDetails.firstName.charAt(0).toUpperCase() + userDetails.lastName.charAt(0).toUpperCase()}
                  </span>:
                  <span className={classes.demoProfile}>
                  <img  className={classes.demoProfile} src={userProfileImage} alt="user" />
                  </span>
                  }

                  {/* {!isProfile ? <img className={classes.demoUser} src={user} alt={'user'} /> : 
                  <span className={classes.demoProfile}>
                  <img  className={classes.demoProfile} src={userProfileImage} alt="user" />
                  </span>} */}
                  <BiChevronDown />
                </Box>

              </Menu.Target>
              <Menu.Dropdown className={classes.dropdown}>
                <Menu.Item onClick={handleOpenProfilePage} className={classes.menuItem}>{titles.myProfile}</Menu.Item>
                <Menu.Item className={classes.menuItem} onClick={doLogout}>{titles.logout}</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Box>
          {/* for mobile */}
          <Box className={classes.mobileNotificationAndHamburger}>
            <img onClick={() => setNotificationsOpened(true)} className={classes.mobileNotification} src={notificaitionUnread ? readnotifications : unreadnotification} alt="hamburger" />
            <Box className={classes.hamburgerContainer}>
              <img onClick={openMobileNavHandler} className={classes.hamburger} src={hamburger} alt="hamburger" />
            </Box>

          </Box>
          <Box className={`${classes.openedMobileNav} ${mobileNavOpened && classes.openTheNav}`}
          >
            <img onClick={() => openMobileNavHandler(false)} className={classes.crossIcon} src={cross} alt="notifications" />

            <Box className={`${classes.dropdownContainer} ${dropdownOpened && classes.dropdownContainerOpened}`}>
              <Box onClick={() => setDropdownOpened(!dropdownOpened)} className={`${classes.menuTargetMobile}`}>
                <Box className={classes.imageAndUsername}>
                  {/* <img className={classes.demoUser} src={user} alt={'user'} />
                   */}
                  {!isProfile ? <img className={classes.demoUser} src={user} alt={'user'} /> : <span className={classes.demoProfile}>
                  {userDetails.firstName.charAt(0).toUpperCase() + userDetails.lastName.charAt(0).toUpperCase()}
                  </span>}
                  <p className={classes.userName} >{userDetails.firstName + " " + userDetails.lastName}</p>

                </Box>
                {
                  dropdownOpened ?
                    <BiChevronUp className={classes.downArrow} />
                    :
                    <BiChevronDown className={classes.downArrow} />
                }

              </Box>
              <Box className={classes.menuItems}>
                <Box onClick={handleOpenProfilePage} className={classes.menuItem}>{titles.myProfile}</Box>
                <Box onClick={doLogout} className={classes.menuItem}>{titles.logout}</Box>
              </Box>

            </Box>
            <Box className={classes.mobileLinks}>
              {getLinks(userType).map(element => {
                return <NavLink onClick={() => setMobileNavOpened(false)} className={({ isActive, isPending }) => isActive ? classes.activeMobileLink : classes.mobileLink} to={element.link} >{element.title}</NavLink>
              })}
            </Box>
            {/* {userType === properties.userType.landlord || userType === properties.userType.tenanat ? null : <Button title={inviteButton()} className={classes.btn} />} */}
            {userType === properties.userType.landlord || userType === properties.userType.tenant ? null : <InvitePopup />}
          </Box>
        </Box>
        <Notifications onClose={() => setNotificationsOpened(false)} opened={notificationsOpened} />
      </Box>
      <Outlet />
    </>
  );
}


export default Header;