import { Box, Modal } from '@mantine/core';
import React, { useEffect, useState } from 'react'
import { useStyles } from './style';
import close from "../../../assets/icons/close.svg"
import { properties } from '../properties';
import {MdDeleteOutline} from "react-icons/md"

const Notifications = ({opened, onClose}) => {

    const { classes, theme } = useStyles(useStyles);
    const { notifications } = properties
    
    return (
            <Modal transitionProps={{
                transition : "slide-left",
                duration : 500
            }} withCloseButton={false} yOffset={10} xOffset={0}  className={classes.notificationsContainer} opened={opened} onClose={() => onClose()} centered>
                <Box className={classes.header}>
                    <p className={classes.title}>Notifications</p>
                    <img src={close} onClick={() => onClose()} alt="close" className={classes.closeIcon} />
                </Box>

                <Box className={classes.notifications}>
                    {
                        notifications.map(notification => (
                            <Box className={`${classes.notification} ${ notification.status === 'read' && classes.read}`}>
                                <Box className={classes.titleAndDate}>
                                    <p className={classes.notificationTitle}>{notification.title}</p>
                                    <p className={classes.notificationDate}>{notification.date}</p>
                                </Box>
                                <MdDeleteOutline className={classes.deleteIcon}/>
                            </Box>
                        ))
                    }
                </Box>
            </Modal>
    )
}

export default Notifications;