import { Button } from '@mantine/core';
import React from 'react';

const index = ({ bgColor, title  , className, onClick , type, isdisabled }) => {
  return (
    <Button type={type} onClick={onClick} disabled= {isdisabled} className={className} style={{ height: '45px', backgroundColor: isdisabled ? 'lightgray' : bgColor }} radius={'xl'}>
      {title}
    </Button>
  );
};

export default index;
