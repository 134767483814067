import React, { createContext, useReducer, useContext, useState } from "react";

// Initial state
const initialState = {
  linkSuccess: false,
  isItemAccess: true,
  isPaymentInitiation: false,
  linkToken: "",
  accessToken: null,
  itemId: null,
  isError: false,
  backend: true,
  profilePictureUrl:'',
  products: ["transactions"],
  linkTokenError: {
    error_type: "",
    error_code: "",
    error_message: "",
  },
};

// Action types
const SET_STATE = "SET_STATE";

// Reducer function
const quickstartReducer = (state, action) => {
  switch (action.type) {
    case SET_STATE:
      return { ...state, ...action.state };
    default:
      return state;
  }
};

// Create context
const QuickstartContext = createContext({
  ...initialState,
  dispatch: () => {},
});

// QuickstartProvider component
const QuickstartProvider = ({ children }) => {
  const [profilePictureUrl, setProfilePictureUrl] = useState(''); // Initial value

  const [state, dispatch] = useReducer(quickstartReducer, initialState);

  return (
    <QuickstartContext.Provider  value={{ ...state, dispatch, profilePictureUrl, setProfilePictureUrl }}>
      {children}
    </QuickstartContext.Provider>
  );
};

// Custom hook to use the context
const useQuickstartContext = () => useContext(QuickstartContext);

export { QuickstartProvider, useQuickstartContext };
