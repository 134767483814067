import { createStyles } from "@mantine/core";

export const useStyles = createStyles(theme => ({
    sendQueryModal : {
        boxShadow : "none",
        "section" : {
            boxShadow : "none",
            "button" : { fontFamily : 'Regular' , fontWeight : "bold" },
            padding : 15,
            [theme.fn.smallerThan(650)] : {
                padding : 5
            },
            'thead' : {
                fontFamily : 'Regular'
            },
            'tr' : {
                'td' : {
            "button" : { fontFamily : 'Regular' , fontWeight : "normal" },
                }
            }
        }
    },
    header : {
       display : "flex",
        justifyContent : "space-between",
        alignItems : "flex-start",
        marginBottom : 20,
    },
    title : {
        fontFamily : `Medium , ${theme.fontFamily}`,
        fontSize : 20,
        color : theme.colors.secondary[0], 
        textAlign : "left",
        [theme.fn.smallerThan(1270)] : {
            width : 1000
        },
        [theme.fn.smallerThan(1050)] : {
            width : 800
        },
        [theme.fn.smallerThan(850)] : {
            width : '85%',
            fontSize : 22
        },

    },
    closeIcon  : {
        color : theme.colors.secondary[0],
        cursor : "pointer",
        fontSize : 20,
        marginTop : 5,
    },
    inputFields : {
        [theme.fn.smallerThan(650)] : {
            flexDirection : "column"
        }
    },
    inputWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginTop : 20,
        width: '100%',
        [theme.fn.smallerThan(650)] : {
            width : '100%'
        }
      },
      textInput : {
        width : '100%',
        "input" : {
            padding: 23,
            borderRadius: 13,
            border: '2px solid white',
            outline: 'none',
            background: "#F7F9FA",
            transition: '.5s',
            fontFamily: `Medium , ${theme.fontFamily}`,
            fontSize: 12,
             
            color: theme.colors.text[1],
            '&:focus': {
                fontFamily: `Regular , ${theme.fontFamily}`,
                fontWeight : "bold",
                color: theme.colors.secondary[0],
              borderColor: theme.colors.primary[0],
              background: theme.colors.tertiary[0],
              boxShadow : "0px 15px 25px 0px #00D89B26",
        }
    },
".mantine-TextInput-error" : {
    fontSize : '13px !important'
}
    },
    textarea: {
    "textarea" : {
        width: '100%',
        height : 150,
        border: '2px solid white',
        outline: 'none',
        borderRadius: 16,
        backgroundColor: '#F7F9FA',
        fontFamily: `Regular , ${theme.fontFamily}`,
        fontSize: 12,
        transition: '.5s',
        padding: 10,
        color : theme.colors.secondary[0],
        '&:focus': {
          borderColor: theme.colors.primary[0],
          boxShadow: `0 0 5px ${theme.colors.primary[0]}`
        },
        
    },
    },
  label: {
    fontFamily: `Regular , ${theme.fontFamily}`,
    color: theme.colors.text[1],
    fontSize: 12,
    opacity : .5
  },

  actions : {
    padding : 25,
    display : "flex",
    justifyContent : "space-around",  
    alignItems : "center",
    [theme.fn.smallerThan(850)] : {
        display : "flex",
        flexDirection : "column-reverse",
        rowGap : 20,
        padding : 8,
        marginTop : 20,   
        
    },
  },
  
    cancel : {
        color : theme.colors.secondary[0],
        fontSize : 12,
        
        letterSpacing : .15,
        fontFamily : `semibold , ${theme.fontFamily}`,
        cursor : "pointer",
        
    },
    apply : {
        fontSize : 14,
        color : theme.colors.white[0],
        fontFamily : `Medium , ${theme.fontFamily}`,
        cursor : "pointer",
        letterSpacing : .15,
        background : theme.colors.primary[0],
        padding : "10px 40px",
        borderRadius : 30,
        // width : 100,
        [theme.fn.smallerThan(850)] : {
            width : "100%",
            textAlign : "center",
        },
    }
}))