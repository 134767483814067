import { useDisclosure } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';

import { Modal, Button, Group, Input, Textarea, Loader } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { useForm, hasLength } from '@mantine/form';
import { useStyles } from './style';
import { properties } from './properties';
import { baseURL } from '../../config/urls';
import { IconCheck, IconX } from '@tabler/icons-react';
import cross from "../../assets/icons/cross.svg"
import {
  getHeader,
  doRedirectIfUnAuth,
  getUserType
} from '../../config/userSession';

const InvitePopup = (prop) => {
  const userType = getUserType();
  const { classes, theme } = useStyles(useStyles);
  const [isLoading, setLoading] = useState(false);

  const [opened, { close, open }] = useDisclosure(false);

  const form = useForm({
    initialValues: {
      ToEmail: '',
      Message: ''
    },
    validate: {
      ToEmail: (value) => value == '' ? 'Please provide the email Id' : (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      //Message: (value) => (!value ? 'Message Required' : null),
      Message: hasLength(
        { min: 2, max: 255 },
        'Message must be 2-255 characters long.'
      )
    }
  });
  const showNotification = (notificationType, message, title) => {
    let colorCode = notificationType == 'success' ? 'secondary.0' : 'red';
    notifications.show({
      id: 'signup-notification',
      withCloseButton: true,
      autoClose: 5000,
      title: title,
      message: message,
      color: colorCode,
      icon:
        notificationType == 'success' ? <IconCheck size="1.1rem" /> : <IconX />,
      style: { backgroundColor: theme.colors.background[0] },
      loading: false
    });
  };

  const handleSubmit = async (values) => {
    if (form.isValid()) {
      setLoading(true);

      try {
        const response = await fetch(`${baseURL}/account/invite`, {
          method: 'POST',
          headers: getHeader(),
          body: JSON.stringify(form.values)
        });
        console.log(response);
        if (response.ok) {
          setLoading(false);
          let apiResponse = await response.text();
          try {
            var dataResponse = JSON.parse(apiResponse);
          } catch (error) {
            throw new Error(apiResponse);
          }
          if(dataResponse.error){
            showNotification('failure', dataResponse.msg, '');
          } else{
            showNotification('success', 'Invitation Sent Successfully', '');
            form.reset();
            close(); // close the popup
          }
          
        } else {
          setLoading(false);

          doRedirectIfUnAuth(response.status);
          var apiResponse = await response.text();
          try {
            var errorResponse = JSON.parse(apiResponse);
          } catch (error) {
            throw new Error(apiResponse);
          }
          console.log('error is', errorResponse);
          showNotification('failure', 'Some Error occured', '');
        }
      } catch (error) {
        setLoading(false);

        console.log(error);
        showNotification('failure', error.message, '');
      }
    }
  };

  return (
    <>
      <Modal
      withCloseButton={false}
        opened={opened}
        onClose={close}
        size="md"
        radius="lg"
        title={
          userType === properties.userType.superAdmin
            ? properties.titles.inviteAdmin
            : properties.titles.inviteLandlord
        }
        centered={true}
        styles={{
          content: {
            padding: '30px',
            boxShadow: '0px 20px 30px rgba(29, 94, 109, 0.2)',
            borderRadius: '24px',
          },
          title: classes.title
        }}
      >
         <img  onClick={close} className={classes.crossIconModal} src={cross} alt="notifications" />
        <form onSubmit={form.onSubmit(handleSubmit)} name="invite-popup">
          <Input.Wrapper
            label="Email"
            mx="auto"
            mb="xl"
            padding={0}
            styles={{
              label: classes.label
            }}
          >
            <Input
              mask=""
              className={classes.textInput}
              {...form.getInputProps('ToEmail')}
              placeholder="example@email.com"
            />
            <div className={classes.errorWrapper}> {form.errors && form.errors.ToEmail ? form.errors.ToEmail:''}</div>
          </Input.Wrapper>
          <Input.Wrapper
            label="Message"
            mx="auto"
            padding={0}
            styles={{
              label: classes.label
            }}
          >
            <Textarea
              mask=""
              className={classes.textInput}
              {...form.getInputProps('Message')}
              placeholder={properties.titles.textAreaDefaultMsg}
              minRows={5}
            />
          </Input.Wrapper>
          <div className={classes.footerWrapper}>
          <Button
          type="submit"
          mt="xl"
          radius="xl"
          ta="right"
          size="lg"
          variant="filled"
          className={classes.cancelButton}
          onClick={close}
        >
          Cancel
        </Button>
            <Button
              type="submit"
              mt="xl"
              radius="xl"
              ta="right"
              size="lg"
              variant="filled"
              className={classes.invitebutton}
              disabled={isLoading}
            >
              {isLoading ? (
                <Loader
                  color="primary.0"
                  size="md"
                  variant="bars"
                  className={classes.loader}
                />
              ) : (
                'Invite'
              )}
            </Button>
          </div>
        </form>
      </Modal>

      <Group position="center">
        <Button
          type="submit"
          mt="sm"
          radius="xl"
          ta="right"
          size="lg"
          variant="filled"
          className={userType === properties.userType.superAdmin ? classes.inviteButtonSuperAdmin: classes.invitebutton}
          onClick={open}
        >
        {userType === properties.userType.superAdmin ? 'Invite Admin' : 'Invite'}
        </Button>
      </Group>
    </>
  );
};

export default InvitePopup;
