import { createStyles } from "@mantine/core";
import Banner from "../../../assets/images/editlistBanner.png"
import {body_min_height} from "../../../utils/const.js"

export const useStyles = createStyles(theme => ({
wrapper : {
    background : 'rgb(224,244,245,0.5)',
    paddingBottom : 100,
    paddingTop : 50,
    backgroundImage : `url(${Banner})`,
    backgroundPosition : "right bottom",
    backgroundRepeat : "no-repeat",
    [theme.fn.smallerThan(800)] : {
        backgroundImage : "none"
    },
    [theme.fn.largerThan(786)]: {
       minHeight:`${body_min_height.desktop} !important`
    }
},
tableContainer : {
    width : '80%',
    margin : "auto",
    [theme.fn.smallerThan(1400)] : {
        width : '90%',
    },
    [theme.fn.smallerThan(1050)] : {
        width : '95%',
        overflowX : "scroll"
    },
},
    table : {
    backgroundColor : theme.colors.background[0],
    width : "100%",
    margin : "auto",
    marginTop : 20,
    marginBlock : 20,
    borderRadius : 10,
    [theme.fn.smallerThan(1050)] : {
        width : 1200
    },
    [theme.fn.smallerThan(900)] : {
        width : 950
    },
},
headerAndSearch : {
    width : '80%',
    margin : "auto",
    display : "flex",
    justifyContent : "space-between",
    [theme.fn.smallerThan(1400)] : {
        width : '90%',
    },
    [theme.fn.smallerThan(1050)] : {
        width : '95%'
    },
    [theme.fn.smallerThan(800)] : {
       flexDirection : "column",
       alignItems :"center",
       rowGap : 10
    },
},
searchAndFilter : {
    display : "flex",
    justifyContent : "space-between",
    columnGap : 20,
    [theme.fn.smallerThan(800)] : {
        width : "100%"
     },
},

searchIcon : {
position : "absolute",
top : 10,
left : 10,
zIndex : 99,
width : 16,
height : 16
},
searchInput: {
    width : 350,
    border : "none !important",
    [theme.fn.smallerThan(800)] : {
        width : "100%"
     },
     "input" : {
        border : "none",
    fontFamily : `Regular , ${theme.fontFamily}`,
    borderRadius : 12,
    fontSize : 13,
     }
},
filterContainer : {
display : "flex",
alignItems : "center",
columnGap : 10,
background : "white",
borderRadius : 12,
paddingLeft : 16,
paddingRight : 16,
height : 35,
[theme.fn.smallerThan(500)] : {
    width : 35,
    height : 35,
    borderRadius : 10,
}
},
filterIcon : {
    width : 12,
    height : 12
},
filter : {
    fontFamily : `Regular, ${theme.fontFamily}`,
    fontSize : 13,
     
    [theme.fn.smallerThan(500)] : {
        display : "none"
    }
},
title : {
    fontFamily : `Medium, ${theme.fontFamily}`,
    fontSize : 21,
    // fontWeight : "bold",
    color : theme.colors.secondary[0],
},
tableHead : {
    backgroundColor : theme.colors.secondary[0],
    height : 50,

},
columnHeading : {
    color : 'white !important',
    fontFamily : `medium, ${theme.fontFamily}`,
    letterSpacing: 0.15,
    fontSize : '13px !important',
    ":first-child" : {
        borderTopLeftRadius : 10,
        paddingLeft : 25,
    },
    ":last-child" : {
        borderTopRightRadius : 10,
    }
},
tableRowWhite : {
    height : 70,
    background : "white",
    border : "none",

},
tableRow : {
    height : 70,
    background : "#F1F6F6",
    border : "none",

},
tableData : {
    borderTop : "none !important",
    fontFamily : `Regular , ${theme.fontFamily}`,
    color : theme.colors.text[1],
    fontSize : '13px !important',

    ":first-child" : {
        paddingLeft : 25,
        width : 400,
        fontFamily : `Regular , ${theme.fontFamily}`,
        [theme.fn.smallerThan(800)] : {
            width : 300
        }
    },
    ":last-child" : {
        width : 180,
        
    },
    ":nth-child(2)": {
        width : 300
    },
    
},
editAndView : {
display : "flex",
alignItems :"flex-start",
justifyContent :"left",
columnGap : 20
},
action : {
    width : 20,
    height : 20
},
status : {
    fontFamily : `Regular, ${theme.fontFamily}`, 
    width :125,
    fontWeight : "bold",
    textAlign : "center",
    padding : 7,
    background: theme.colors.primary,
    fontSize : 10.5,
    borderRadius : 20,
    color: '#FFFFFF',
    letterSpacing: 0.20,
    cursor : "pointer",

},
active : {
    background: theme.colors.background[4],
    color: '#FFFFFF',
},
disabled : {
    background: '#DBDBDB',
    color: '#9C9C9C',
},
inactive : {
    background: '#F5DBDB',
    color: theme.colors.text[5],
},
inprogress : {
    background : theme.colors.background[5],
    color : "#64B216"
},
pagination : {
    display : "flex",
    justifyContent :"center",
    border : "none !important",
    marginTop : 50,
    "button" : {
        border:"none !important",
        backgroundColor:"transparent"
    }
}
}))