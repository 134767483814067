const sessionKey = 'triplenet-token';
var sessionTokenName = 'accessToken';

const getBearerToken = (tokenName) => {
  let localStorageData  = JSON.parse(localStorage.getItem(sessionKey))
  return localStorageData && 'token' in localStorageData? localStorageData.token[tokenName]: '';
};

const getAuthHeader = () => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${getBearerToken(sessionTokenName)}`,
  };
};

const getUnAuthHeader = () => {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
};
const getHeader = () => {
    if (!isUserLoggedIn) return getUnAuthHeader();
  
    return getAuthHeader();
  };

const isUserLoggedIn = () => {
  return JSON.parse(localStorage.getItem(sessionKey))? true: false;
}

const updateLoggedInData = (data) => {
    console.log('data is ', data)
    localStorage.setItem(sessionKey, JSON.stringify(data));
  };
  
const clearUserData = () => {
    document.cookie = sessionTokenName +'=; Path=/; max-age=0';
    localStorage.clear();
  };

const getUserData = () => {
    return JSON.parse(localStorage.getItem(sessionKey));
  };

const getUserType = () => {
    let localStorageData  = JSON.parse(localStorage.getItem(sessionKey))
    return localStorageData && 'userType' in localStorageData? localStorageData.userType[0]: null;
  };

const doRedirectIfUnAuth = (statusCode) => {
  if(statusCode===401) {
    clearUserData()
    window.location.reload();
  }
  return;
  };
module.exports =  {
    isUserLoggedIn,
    getHeader,
    updateLoggedInData,
    clearUserData,
    getUserData,
    getUserType,
    doRedirectIfUnAuth,
    sessionKey
}