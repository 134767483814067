export const properties = {
  links : {
      superAdmin : [
          {title:'Admin', link:'/superadmin/'},
          {title:'Transaction', link:'/superadmin/transaction'},
          {title:'Contact Us Messages', link:'/superadmin/contact'},
      ],
      admin : [
          {title:'Dashboard', link:'/admin/'},
          {title:'Landlord', link:'/admin/landlord'},
          {title:'Tenant', link:'/admin/tenant'},
          {title:'Property', link:'/admin/property'},
          {title:'Transaction', link:'/admin/transaction'},
          {title:'Plan', link:'/admin/plan'},
      ],
      landLord : [
          {title:'Dashboard', link:'/landlord/'},
          {title:'Tenant', link:'/landlord/tenant'},
          {title:'Property', link:'/landlord/property'},
          {title:'Transaction', link:'/landlord/transaction'},
          {title:'Linked Account', link:'/landlord/linked-account'},
          {title:'Subscriptions', link:'/landlord/subscriptions' }
      ],
      tenant : [
          {title:'Dashboard', link:'/tenant/'},
          {title:'Landlord', link:'/tenant/landlord'},
          {title:'Property', link:'/tenant/property'},
          {title:'Rent', link:'/tenant/rent'},
          {title:'Transaction', link:'/tenant/transaction'},
          {title:'Linked Account', link:'/tenant/linked-account'},
      ],
  },
  notifications : [
      {
          title : '@admin name account created by him!',
          date : 'March 12, 2023',
          status : 'unread',
      },
      {
          title : '@admin name account created by him!',
          date : 'March 12, 2023',
          status : 'unread',
      },
      {
          title : '@admin name account created by him!',
          date : 'March 12, 2023',
          status : 'read',
      },
      {
          title : '@admin name account created by him!',
          date : 'March 12, 2023',
          status : 'read',
      },
      {
          title : '@admin name account created by him!',
          date : 'March 12, 2023',
          status : 'read',
      },
      {
          title : '@admin name account created by him!',
          date : 'March 12, 2023',
          status : 'read',
      },
      {
          title : '@admin name account created by him!',
          date : 'March 12, 2023',
          status : 'read',
      },

  ],
  titles : {
      inviteAdmin : "Invite Admin",
      inviteLandlord: "Invite Landlord",
      invite : "Invite " ,
      textAreaDefaultMsg: 'Write Message here',
      myProfile : 'My Profile',
      logout : 'Log out'
  },
  userType :{
      superAdmin:'SuperAdmin',
      admin: 'Admin',
      landlord: 'Landlord',
      tenant: 'Tenant'
  }
}

export const getLinks = (user) => {
  if(user === 'SuperAdmin'){
return properties.links.superAdmin
  }
  if(user === 'Admin'){
return properties.links.admin
  }
  if(user === 'Landlord'){
return properties.links.landLord
  }
  if(user === 'Tenant'){
return properties.links.tenant
  }
} 